<template>
  <div class="courseList">
    <div class="box">
      <div class="content">
        <div class="listContainer">
          <div class="left-list">
            <div class="classBox">
              <p class="label">讲师等级：</p>
              <div class="classContent">
                <div class="class1 class">
                  <p
                    class="classItem"
                    :class="{ classAcitve: calssIndex1 === index }"
                    @click="handleClass1(item, index)"
                    v-for="(item, index) in class1"
                    :key="index + 'class1'"
                  >
                    {{ item.name }}
                  </p>
                </div>
              </div>
            </div>
            <div class="listBox">
              <div
                class="listItem"
                v-for="item in lecturerData"
                :key="item.ucode"
                @click="handleDetail(item)"
              >
                <!-- <p class="label" v-if="item.courseType">{{ ConstData.courseLabelType[item.courseType].text  }}</p> -->
                <div class="img">
                  <img
                    src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/ss-pc/lectureImgBg.png"
                    class="cover outside"
                    alt=""
                  />
                  <img
                    :src="
                      item.avatar
                        ? item.avatar
                        : 'https://wd-appdownload.oss-cn-beijing.aliyuncs.com/ss-pc/lecturerDefault.png'
                    "
                    class="cover inside"
                    alt=""
                  />
                </div>

                <div class="info">
                  <div class="title">
                    <p class="name" :title="item.name">{{ item.name }}</p>
                    <img
                      :src="
                        item.gender === 1
                          ? 'https://wd-appdownload.oss-cn-beijing.aliyuncs.com/ss-h5/male.png'
                          : 'https://wd-appdownload.oss-cn-beijing.aliyuncs.com/ss-h5/female.png'
                      "
                      class="cover"
                      alt=""
                    />
                  </div>

                  <div class="bottom">
                    <img
                      v-if="!!item.teacherLevelName"
                      src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/ss-pc/letturerBg.png"
                      alt=""
                    />
                    <p v-if="!!item.teacherLevelName">
                      {{ item.teacherLevelName }}
                    </p>
                  </div>
                  <p class="num">{{ item.courseIdList.length }}个已授课程</p>
                </div>
              </div>
            </div>
            <article v-if="noData" class="ListEmptyData">
              <img
                src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/ss-pc/empty-lecturer.png"
                alt
              />
              <p>没有找到相关内容</p>
            </article>
          </div>
        </div>
        <div class="pagination">
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page.sync="pageNum"
            :page-size="pageSize"
            layout="prev, pager, next, jumper"
            :total="total"
          >
          </el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import _ from "lodash";
export default {
  data() {
    return {
      noData: null,
      total: 0,
      pageNum: 1,
      pageSize: 15,
      courseRankingTabIndex: 0,
      allCourseTabIndex: 0,
      allCourseTab: ["全部", "初级讲师", "中级讲师", "高级讲师"],
      courseRankingTab: ["按日", "按周"],
      calssIndex1: 0,
      calssIndex2: 0,
      calssIndex3: 0,
      class1: [{ name: "全部", teacherLevel: "" }],
      class2: [],
      class3: [],
      rankingList: [],
      lecturerData: [],
      currentClassCode: "",
      teacherLevel: null,
    };
  },
  created() {
    this.findTeacherLevel();
    this.getLecturerList();
    this.getHotList(0);
  },
  computed: {
    class2Arr() {
      return this.class2.filter((c) => c.showTag == true);
    },
    class3Arr() {
      return this.class3.filter((c) => c.showTag == true);
    },
  },
  watch: {
    "$store.state.courseCenterSearchName": {
      deep: true, //深度监听设置为 true
      handler: function (newVal) {
        console.log("数据发生变化啦"); //修改数据时，能看到输出结果
        console.log(newVal);
        this.pageNum = 1;
        this.getLecturerList();
      },
    },
  },
  methods: {
    filterTreeData(data) {
      let newArr = _.filter(data, (item) => {
        return item.showTag;
      });
      return newArr.map((item) => {
        if (item.children) {
          item.children = this.filterTreeData(item.children);
        }
        return item;
      });
    },
    getHotList(type) {
      const params = {
        pageNum: 1,
        pageSize: 10,
      };
      const data = {
        scope: type, // 1 按日 2 按周
      };
      this.$api.courseCenter.courseTopCount({ params, data }).then((res) => {
        if (res.data) {
          this.rankingList = res.data || [];
        }
      });
    },
    handleClass1(item, index) {
      this.teacherLevel = item.id;
      this.pageNum = 1;
      this.calssIndex1 = index;
      this.getLecturerList();
    },
    // 获取所有分类
    findTeacherLevel() {
      this.$api.lecturer.findTeacherLevel({}).then((res) => {
        if (res.data && res.data.length) {
          this.class1 = this.class1.concat(res.data);
        }
      });
    },
    getLecturerList() {
      const params = {
        pageNum: this.pageNum,
        pageSize: this.pageSize,
      };
      const data = {
        status: 1,
        teacherLevel: this.teacherLevel,
      };
      this.$api.lecturer.getLecturerList({ params, data }).then((res) => {
        if (res.data) {
          if (res.data.list && res.data.list.length) {
            this.noData = false;
          } else {
            this.noData = true;
          }
          this.lecturerData = res.data.list || [];
          // this.lecturerData =
          //   this.lecturerData.concat(
          //     res.data.list,
          //     res.data.list,
          //     res.data.list,
          //     res.data.list,
          //     res.data.list,
          //     res.data.list,
          //     res.data.list,
          //     res.data.list,
          //     res.data.list
          //   ) || [];
          this.total = res.data.total;
        }
      });
    },
    handleDetail(item) {
      this.$router.push({
        path: "/lecturer/detail",
        query: {
          ucode: item.ucode,
        },
      });
    },
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.pageNum = val;
      this.getLecturerList();
    },
    handleAllCourseTab(index) {
      this.allCourseTabIndex = index;
      this.pageNum = 1;
      this.getLecturerList();
    },
    handleCourseRankingTab(index) {
      this.courseRankingTabIndex = index;
      this.getHotList(index);
    },
  },
};
</script>

<style lang="scss" src="../assets/css/list.scss" scoped></style>
